$(function() {

    'use strict';

    // STiCKY //
    function sticky() {

        let sticky_point = $('#header').innerHeight() + 100;

        $('#header').clone().attr({
            id: 'header-sticky',
            class: ''
        }).insertAfter('header');

        $(window).scroll(function() {

            if ($(window).scrollTop() > sticky_point) {
                $('#header-sticky').slideDown(300).addClass('header-sticky');
                $('#header .menu ul').css({'visibility': 'hidden'});
            } else {
                $('#header-sticky').slideUp(100).removeClass('header-sticky');
                $('#header .menu ul').css({'visibility': 'visible'});
            }

        });

    }

    // SHOW/HIDE MOBILE MENU //
    function show_hide_mobile_menu() {

        $('.mobile-menu a').on('click', function(e) {

            e.preventDefault();
            e.stopPropagation();

            $('#mobile-menu').toggleClass('open');
            $('body').toggleClass('body-overlay');

        });

        $('body').on('click', function() {
            if ($('#mobile-menu').hasClass('open')) {
                $('#mobile-menu').removeClass('open');
                $('body').removeClass('body-overlay');
            }
        });

    }

    // MOBILE MENU //
    function mobile_menu() {

        if ($(window).width() < 991) {

            if ($('#menu').length > 0) {

                if ($('#mobile-menu').length < 1) {

                    $('#menu').clone().attr({
                        id: 'mobile-menu',
                        class: ''
                    }).insertAfter('#header');

                }

                $('#mobile-menu').on('click', function(e) {
                    e.stopPropagation();
                });

                if ($('#mobile-menu > .dropdown > a').hasClass('current')) {
                    $('.category-unseleted').fadeOut(300);
                }

                $('#mobile-menu > .dropdown > a').on('click', function(e) {

                    e.preventDefault();
                    e.stopPropagation();

                    $('#mobile-menu > .dropdown > a').removeClass('current').next('ul').fadeOut(300);
                    $('.category-unseleted').fadeOut(300);
                    $(this).toggleClass('current').next('ul').stop().fadeIn(300);

                });
            }

        } else {

            $('#mobile-menu').removeClass('open');
            $('body').removeClass('body-overlay');

        }

    }

    // MENU //
    function menu() {

        $('.menu').on('mouseenter', function() {
            setTimeout(function() {
                $('.category-unseleted').fadeOut(250);
            }, 250);
        }).on('mouseleave', function() {
            setTimeout(function() {
                $('.category-unseleted').fadeIn(250);
            }, 250);
        });
    }

    // SHOW/HIDE SCROLL UP //
    function show_hide_scroll_top() {

        if ($(window).scrollTop() > $(window).height() / 2) {
            $('#scroll-up').fadeIn(300);
        } else {
            $('#scroll-up').fadeOut(300);
        }

    }

    // SCROLL UP //
    function scroll_up() {

        $('#scroll-up').on('click', function() {
            $('html, body').animate({
                scrollTop: 0
            }, 800);
            return false;
        });

    }

    // MOBILE COOKIES //
    function mobile_cookies() {

        $('.cookie-popup h2').on('click', function() {
            $(this).parent().toggleClass('maximized');
        });

    }

    // CART //
    function show_hide_cart() {

        $('.cart').on('click', function(e) {

            e.preventDefault();
            e.stopPropagation();

            $('.cart-content').toggleClass('open');

        });

        $('.cart-content').on('click', function(e) {
            e.stopPropagation();
        });

        $('.close-cart-content').on('click', function(e) {
            e.preventDefault();
            $('.cart-content').removeClass('open');
        });

        $('body').on('click', function() {
            if ($('.cart-content').hasClass('open')) {
                $('.cart-content').removeClass('open');
            }
        });
    }

    // MODAL //
    function modal() {

        $('[data-toggle="modal"]').on('click', function(e) {

            e.preventDefault();

            let target = $(this).data('target');

            $('body').addClass('modal-open').append('<div class="modal-backdrop fade show"></div>').find(target).fadeIn(300).addClass('show');

            $('[data-dismiss="modal"]').on('click', function(e) {
                e.preventDefault();
                $('body').removeClass('modal-open').find(target).fadeOut(300).removeClass('show');
                $('.modal-backdrop').remove();
            });

        });
    }

    // FORGOT MODAL //
    function forgot_modal() {
        $('a[data-target="#forgotModal"]').on('click', function() {
            $('body').removeClass('modal-open').find('#authModal').fadeOut(300).removeClass('show');
            $('.modal-backdrop').remove();
        });
    }

    // WISHLIST MODAL //
    function wishlist_modal() {
        $('a[data-target="#authModal"]').on('click', function() {
            $('body').removeClass('modal-open').find('#wishlistModal').fadeOut(300).removeClass('show');
            $('.modal-backdrop').remove();
        });
    }

    // MOBILE HEADER TOP //
    function mobile_header_top() {

        $('#header-top span:first-child').on('click', function() {
            $(this).siblings().stop().fadeToggle(250);
            $('#header-top').stop().toggleClass('opened');
        });

    }

    // MOBILE SEARCH //
    function mobile_search() {

        $('.quick-links .mobile-search a').on('click', function(e) {
            e.preventDefault();
            $(this).find('i').toggleClass('icon-magnifier icon-times');
            $('.search-form').slideToggle(300);
        });

    }

    // PRICE RANGE //
    function price_range() {

        $('.slider-range-price').each(function() {
            let min = parseFloat($(this).data('min'));
            let max = parseFloat($(this).data('max'));
            let value_min = parseFloat($(this).data('value-min'));
            let value_max = parseFloat($(this).data('value-max'));

            if (typeof $.fn.slider !== 'undefined') {
                $('.price-filter').slider({
                    range: true,
                    min: min,
                    max: max,
                    values: [value_min, value_max],
                    slide: function(event, ui) {
                        $('.amount-range-price .from').html(ui.values[0]);
                        $('.amount-range-price .to').html(ui.values[1]);
                    },
                    stop: function(event, ui) {
                        $('input[name="price_min"]').val(ui.values[0]);
                        $('input[name="price_max"]').val(ui.values[1]);
                    }
                });
            }
        });

    }

    // MOBILE FILTERS //
    function mobile_filters() {

        $('.show-filters').on('click', function(e) {

            e.preventDefault();
            $(this).html($(this).html() == 'Ascunde filtre' ? 'Afișează filtre' : 'Ascunde filtre').next('.filters').stop().slideToggle(300);

        });

    }

    // QUANTITY //
    function quantity() {
        $('<div class="quantity-nav"><div class="quantity-button quantity-up quantity-button">+</div><div class="quantity-button quantity-down quantity-button">-</div></div>').insertAfter('.quantity input');
        $('.quantity').each(function() {
            let spinner = $(this),
                newVal = 0,
                input = spinner.find('input[type="number"]'),
                btnUp = spinner.find('.quantity-up'),
                btnDown = spinner.find('.quantity-down'),
                min = input.attr('min'),
                max = input.attr('max');

            btnUp.click(function() {
                let oldValue = parseFloat(input.val());
                if (oldValue >= max) {
                    newVal = oldValue;
                } else {
                    newVal = oldValue + 1;
                }
                spinner.find('input').val(newVal);
                spinner.find('input').trigger('change');
                updateCart();
            });

            btnDown.click(function() {
                let oldValue = parseFloat(input.val());
                if (oldValue <= min) {
                    newVal = oldValue;
                } else {
                    newVal = oldValue - 1;
                }
                spinner.find('input').val(newVal);
                spinner.find('input').trigger('change');
                updateCart();
            });
        });

        function updateCart() {
            let data = $('#cart-form').serialize();
            axios.post(`/cart`, data)
                .then((data) => {
                    $('#cart-contents').html(data.data);
                    quantity();
                })
                .catch((error) => {
                    //
                });

        }
    }

    // DELIVERY ADDRESS //
    function delivery_address() {

        let address = $('.delivery-address');

        address.fadeOut(0);

        if ($('#to_delivery').is(':checked')) {
            address.fadeIn(0);
        }

        $('input:radio[name="shipping"]').on('change', function() {
            if ($(this).val() === 'different') {
                address.fadeIn(250);
            } else {
                address.fadeOut(250);
            }
        });

    }

    // SMOOTH SCROLLING //
    function smooth_scrolling() {

        $('#header-top a').on('click', function() {

            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {

                let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 150
                    }, 400);

                    return false;
                }

            }

        });

    }


    $(document).ready(function() {

        // STICKY //
        sticky();

        // MENU //
        menu();

        $('.menu').superfish({
            popUpSelector: 'ul',
            delay: 500,
            animation: {
                opacity: 'show'
            },
            speed: 'fast',
            autoArrows: false
        });

        // SHOW/HIDE MOBILE MENU //
        show_hide_mobile_menu();

        // MOBILE MENU //
        mobile_menu();

        // SHOW/HIDE SCROLL UP
        show_hide_scroll_top();

        // SCROLL UP //
        scroll_up();

        // MOBILE COOKIES //
        mobile_cookies();

        // FORGOT MODAL //
        forgot_modal();

        // WISHLIST MODAL //
        wishlist_modal();

        // CART //
        show_hide_cart();

        // MODAL //
        modal();

        // MOBILE HEADER TOP //
        if ($(window).width() < 575) {
            mobile_header_top();
        }

        // MOBILE SEARCH //
        mobile_search();

        // PRICE RANGE //
        price_range();

        // MOBILE FILTERS //
        mobile_filters();

        // PRODUCT GALLERY //
        $('.product-main-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            infinite: false,
            arrows: true,
            prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
            nextArrow: '<span class="slick-next icon-arrow-right"></span>',
            fade: true,
        });

        $('.product-thumbs-slider')
            .on('init', function() {
                $('.product-thumbs-slider .slick-slide.slick-current').addClass('is-active');
            }).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: false,
            infinite: false,
            arrows: true,
            prevArrow: '<span class="slick-prev icon-arrow-left"></span>',
            nextArrow: '<span class="slick-next icon-arrow-right"></span>',
            responsive: [
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 4
                    }
                }
            ]
        });

        $('.product-main-slider').on('afterChange', function(event, slick, currentSlide) {
            $('.product-thumbs-slider').slick('slickGoTo', currentSlide);
            let currrentNavSlideElem = '.product-thumbs-slider .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.product-thumbs-slider .slick-slide.is-active').removeClass('is-active');
            $(currrentNavSlideElem).addClass('is-active');
        });

        $('.product-thumbs-slider').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            let goToSingleSlide = $(this).data('slick-index');
            $('.product-main-slider').slick('slickGoTo', goToSingleSlide);
        });

        // FANCYBOX //
        $('[data-fancybox]').fancybox();

        // QUANTITY //
        quantity();

        // DELIVERY ADDRESS //
        delivery_address();

        // SMOOTH SCROLLING //
        smooth_scrolling();

    });

    // WINDOW SCROLL //
    $(window).scroll(function() {

        show_hide_scroll_top();

    });

    // WINDOW RESIZE //
    $(window).resize(function() {

        mobile_menu();

    });

    $('#register-form').on('submit', function(e) {
        e.preventDefault();
        let data = $(this).serialize();
        axios.post('/auth/register', data)
            .then(data => {
                toastr['success'](data.data.message);
                $('#authModal').fadeOut(300).removeClass('show');
                $('.modal-backdrop').remove();
            })
            .catch(error => {
                if (error.response.data.errors.email) {
                    toastr['error'](error.response.data.errors.email);
                }
                if (error.response.data.errors.password) {
                    toastr['error'](error.response.data.errors.password);
                }
            });
    });

    $('#login-form').on('submit', function(e) {
        e.preventDefault();
        let data = $(this).serialize();
        axios.post('/auth/login', data)
            .then(data => {
                location.href = '/contul-meu/date-personale';
            })
            .catch(error => {
                if (error.response.status === 403) {
                    toastr['error'](error.response.data.message);
                } else {
                    toastr['error'](error.response.data.errors.email);
                }
            });
    });

    $('#sort').on('change', function(e) {
        $(this).parents('form')[0].submit();
    });

    $('#size-id').on('change', function() {
        let button = $('#add-cart');
        button.removeClass('disabled');
    });

    $('#add-cart').on('click', function(e) {
        e.preventDefault();
        let data = {
            product: $('#product-id').val(),
            variant: $('#variant-id').val(),
            size: $('#size-id').val(),
            image: $('#image').val(),
        };
        axios.post(`/cart/${data.product}`, data)
            .then(data => {
                getFreeShipping();
                let cartContent = $('.cart-content');
                cartContent.removeClass('open');
                $('#cart-body').html(data.data);
                cartContent.addClass('open');
            })
            .catch(error => {
                //
            });
    });
    window.checkSizesLook = function() {
        let hasSize = false;
        $('select[name^="size"]').each(function() {
            if ($(this).val().length !== 0) {
                hasSize = true;
            }
        });
        if (hasSize) {
            $('#add-cart-look').removeClass('disabled');
        } else {
            $('#add-cart-look').addClass('disabled');
        }
    };

    $('select[name^="size"]').on('change', function() {
        checkSizesLook();
    });

    $('#add-cart-look').on('click', function(e) {
        e.preventDefault();

        let data = $('#look-form').serialize();

        axios.post(`/cart/look`, data)
            .then(data => {
                getFreeShipping();
                let cartContent = $('.cart-content');
                cartContent.removeClass('open');
                $('#cart-body').html(data.data);
                cartContent.addClass('open');
                $('select[name^="size"]').each(function() {
                    $(this).val(null).trigger('change');
                });
            })
            .catch(error => {
                //
            });
    });

    $('#add-wishlist, .add-to-wishlist').on('click', function(e) {
        if($(this).data('target') !== undefined)
        {
            return false;
        }
        e.preventDefault();
        let url = $(this).attr('href');
        axios.post(url)
            .then(data => {
                toastr['success']('Produsul a fost adăugat la favorite');
                $(this).addClass('disabled');
                $(this).toggleClass('added');
            })
            .catch(error => {
                toastr['error'](error);
            });
    });

    $('#delete-wishlist').on('click', function(e) {
        e.preventDefault();
        let product = $('#product-id').val();
        axios.delete(`/contul-meu/wishlist/${product}`)
            .then(data => {
                toastr['success']('Produsul a fost șters de la favorite');
                $(this).addClass('disabled');
            })
            .catch(error => {
                toastr['error'](error);
            });
    });

    $('.delete-wishlist').on('click', function(e) {
        e.preventDefault();
        axios.delete($(this).attr('href'))
            .then(data => {
                location.reload();
            })
            .catch(error => {
                console.log(error);
                toastr['error'](error);
            });
    });

    $(document).on('click', '.cart-delete-item', function(e) {
        e.preventDefault();
        let url = $(this).attr('href');
        axios.delete(url)
            .then(() => {
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    });
    window.getFreeShipping = () => {

    };
    window.deleteItem = function(url) {
        axios.delete(url)
            .then(data => {
                getFreeShipping();
                $('#cart-body').html(data.data);
            })
            .catch(error => {
                console.log(error);
            });

        return false;
    };
    $('#apply-voucher').on('click', function(e) {
        e.preventDefault();
        let voucher = $('#discount-voucher').val();
        axios.post('/voucher', {voucher: voucher})
            .then(data => {
                $('#overview-cart').html(data.data);
                $('#discount-container').fadeOut();
            })
            .catch(error => {
                if (error.response.status === 419) {
                    window.location.reload();
                    return false;
                }
                toastr['error'](error.response.data.errors.voucher);
            });
    });
    $('.country').on('change', function() {
        let val = $(this).val();
        let $county = $(this).data('county');
        let $city = $($county).data('city');
        if ($('#shipping-country-select:visible').length !== 0) {
            val = $('#shipping-country-select').val();
        }

        if(country === parseInt($(this).val()))
        {
            $($county).attr('disabled', false);
            $($city).attr('disabled', false);
            axios.get('/county')
                .then(data => {
                    $($county).empty();
                    data.data.map(item => {
                        let newOption = new Option(item.name, item.id, false, false);
                        $($county).append(newOption);
                    });
                    $($county).trigger('change');
                })

        }
        else
        {
            $($county).empty().attr('disabled', true);
            $($city).empty().attr('disabled', true);
        }
        axios.get(`/checkout/getShippingMethods?country=${val}`)
            .then((data) => {
                $('#shipping-methods-container').html(data.data);
                axios.post('/checkout/update', {shipping_method: null})
                    .then((data) => {
                        $('#overview-cart').html(data.data);
                    });
                axios.get(`/checkout/getPaymentMethods?country=${val}`)
                    .then((data) => {
                        $('#payment-methods-container').html(data.data);
                    });
            });
    });
    $('.county-select').on('change', function() {
        let county = $(this).val();
        let $city = $(document).find($(this).data('city'));
        axios.get(`/county/${county}/cities`)
            .then(data => {
                $city.empty().trigger('change');
                data.data.map(item => {
                    let newOption = new Option(item.name, item.id, false, false);
                    $city.append(newOption).trigger('change');
                });
            })
            .catch(error => {

            });
    });
    $(document).on('click', 'input[name="shipping_method"]', function() {
        let shippingMethod = $(this).val();

        axios.post('/checkout/update', {shipping_method: shippingMethod})
            .then((data) => {
                $('#overview-cart').html(data.data);
            });
    });

});

window.closeCart = function() {
    $('.cart-content').removeClass('open');
    return false;
};
